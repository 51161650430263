import React from "react";
import LogoImg from "../assets/img/logo.svg";
import "../../styles/logo.scss";

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImg} alt="MUFG Intime Logo" />
    </div>
  );
};

export default Logo;

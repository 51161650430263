import React from "react";
import * as d3 from "d3";

class Chart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.update();
  }

  update = () => {
    const dataset = this.props.dataset.map((item) => ({
      val: parseInt(item.val),
      rating: item.rating,
    }));
    dataset.pop();

    let w = 400;
    let h = 200;
    let margin = 30;
    let barPadding = 30;

    d3.select("svg").remove();

    let yMax = d3.max(dataset, (d) => d.val);
    let yScale = d3
      .scaleLinear()
      .domain([0, yMax * 1.2])
      .range([h, 0]);

    let svg = d3
      .select(".chart")
      .append("svg")
      .attr("width", w + 2 * margin)
      .attr("height", h + 2 * margin)
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");

    var defs = svg.append("defs");
    var lg = defs
      .append("linearGradient")
      .attr("id", "Gradient2")
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 1);

    lg.append("stop").attr("offset", "0%").attr("stop-color", "#E60000");

    lg.append("stop").attr("offset", "100%").attr("stop-color", "#820000");

    svg
      .selectAll("rect")
      .data(dataset)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * (w / dataset.length))
      .attr("rx", 7)
      .attr("y", (d) => yScale(d.val))
      .attr("cursor", "pointer")
      .attr("width", w / dataset.length - barPadding)
      .attr("height", (d) => h - yScale(d.val))
      .attr("fill", "url(#Gradient2)");

    svg
      .selectAll("text")
      .data(dataset)
      .enter()
      .append("text")
      .text((d) => d.val)
      .attr(
        "x",
        (d, i) =>
          i * (w / dataset.length) + (w / dataset.length - barPadding) / 2
      )
      .attr("y", (d) => yScale(d.val) + 10)
      .attr("font-family", "sans-serif")
      .attr("font-size", "16px")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("dy", "10");

    const x = d3
      .scaleBand()
      .rangeRound([0, w])
      .domain(dataset.map((d) => d.rating));

    const xAxis = d3.axisBottom(x);
    svg
      .append("g")
      .attr("class", "axis")
      .attr("border", "1")
      .attr("transform", `translate(0, ${h})`)
      .call(xAxis)
      .attr("stroke-width", 0)
      .selectAll(".tick text")
      .attr("x", "-15")
      .attr("font-family", "Poppins")
      .attr("font-size", "16px")
      .attr("fill", "#252D59")
      .attr("font-weight", "500")
      .attr("text-anchor", "middle");
  };

  render() {
    this.update();
    return <div className="chart" />;
  }
}

export default Chart;
